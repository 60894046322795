/*
 Sticky-kit v1.1.2 | MIT | Leaf Corcoran 2015 | http://leafo.net
*/
(function(){var c,f;c=this.jQuery||window.jQuery;f=c(window);c.fn.stick_in_parent=function(b){var A,w,B,n,p,J,k,E,t,K,q,L;null==b&&(b={});t=b.sticky_class;B=b.inner_scrolling;E=b.recalc_every;k=b.parent;p=b.offset_top;n=b.spacer;w=b.bottoming;null==p&&(p=0);null==k&&(k=void 0);null==B&&(B=!0);null==t&&(t="is_stuck");A=c(document);null==w&&(w=!0);J=function(a){var b;return window.getComputedStyle?(a=window.getComputedStyle(a[0]),b=parseFloat(a.getPropertyValue("width"))+parseFloat(a.getPropertyValue("margin-left"))+
parseFloat(a.getPropertyValue("margin-right")),"border-box"!==a.getPropertyValue("box-sizing")&&(b+=parseFloat(a.getPropertyValue("border-left-width"))+parseFloat(a.getPropertyValue("border-right-width"))+parseFloat(a.getPropertyValue("padding-left"))+parseFloat(a.getPropertyValue("padding-right"))),b):a.outerWidth(!0)};K=function(a,b,q,C,F,u,r,G){var v,H,m,D,I,d,g,x,y,z,h,l;if(!a.data("sticky_kit")){a.data("sticky_kit",!0);I=A.height();g=a.parent();null!=k&&(g=g.closest(k));if(!g.length)throw"failed to find stick parent";
v=m=!1;(h=null!=n?n&&a.closest(n):c("<div />"))&&h.css("position",a.css("position"));x=function(){var d,f,e;if(!G&&(I=A.height(),d=parseInt(g.css("border-top-width"),10),f=parseInt(g.css("padding-top"),10),b=parseInt(g.css("padding-bottom"),10),q=g.offset().top+d+f,C=g.height(),m&&(v=m=!1,null==n&&(a.insertAfter(h),h.detach()),a.css({position:"",top:"",width:"",bottom:""}).removeClass(t),e=!0),F=a.offset().top-(parseInt(a.css("margin-top"),10)||0)-p,u=a.outerHeight(!0),r=a.css("float"),h&&h.css({width:J(a),
height:u,display:a.css("display"),"vertical-align":a.css("vertical-align"),"float":r}),e))return l()};x();if(u!==C)return D=void 0,d=p,z=E,l=function(){var c,l,e,k;if(!G&&(e=!1,null!=z&&(--z,0>=z&&(z=E,x(),e=!0)),e||A.height()===I||x(),e=f.scrollTop(),null!=D&&(l=e-D),D=e,m?(w&&(k=e+u+d>C+q,v&&!k&&(v=!1,a.css({position:"fixed",bottom:"",top:d}).trigger("sticky_kit:unbottom"))),e<F&&(m=!1,d=p,null==n&&("left"!==r&&"right"!==r||a.insertAfter(h),h.detach()),c={position:"",width:"",top:""},a.css(c).removeClass(t).trigger("sticky_kit:unstick")),
B&&(c=f.height(),u+p>c&&!v&&(d-=l,d=Math.max(c-u,d),d=Math.min(p,d),m&&a.css({top:d+"px"})))):e>F&&(m=!0,c={position:"fixed",top:d},c.width="border-box"===a.css("box-sizing")?a.outerWidth()+"px":a.width()+"px",a.css(c).addClass(t),null==n&&(a.after(h),"left"!==r&&"right"!==r||h.append(a)),a.trigger("sticky_kit:stick")),m&&w&&(null==k&&(k=e+u+d>C+q),!v&&k)))return v=!0,"static"===g.css("position")&&g.css({position:"relative"}),a.css({position:"absolute",bottom:b,top:"auto"}).trigger("sticky_kit:bottom")},
y=function(){x();return l()},H=function(){G=!0;f.off("touchmove",l);f.off("scroll",l);f.off("resize",y);c(document.body).off("sticky_kit:recalc",y);a.off("sticky_kit:detach",H);a.removeData("sticky_kit");a.css({position:"",bottom:"",top:"",width:""});g.position("position","");if(m)return null==n&&("left"!==r&&"right"!==r||a.insertAfter(h),h.remove()),a.removeClass(t)},f.on("touchmove",l),f.on("scroll",l),f.on("resize",y),c(document.body).on("sticky_kit:recalc",y),a.on("sticky_kit:detach",H),setTimeout(l,
0)}};q=0;for(L=this.length;q<L;q++)b=this[q],K(c(b));return this}}).call(this);

'use strict';

(function ($, window, document) {

  $(function () {

    // To allow:active styles to work in Mobile Safari
    if (!$('.ie8').length) {
      document.addEventListener('touchstart', function () {}, true);
    }

    // Listen for the jQuery ready event on the document
    $(function () {

      (function () {

        // SP Navigation
        var navigation = {

          element: {
            $menuToggle: $('.nav-toggle, .js_nav-close, .js_reservation-sp'),
            $menuToggleImg: $('.nav-toggle img'),
            $menuReservation: $('.js_reservation'),
            $menu: $('.nav-sp'),
            overlayDiv: '<div class="menu-overlay"></div>'
          },

          init: function init() {
            this.click();
          },
          click: function click() {
            var _this = this;
            var docClickHandlerAdded = false;

            var addDocumentClick = function addDocumentClick() {
              $(document).on('click', function () {
                if (_this.element.$menu.is(':visible')) {
                  _this.element.$menuReservation.show();
                  _this.toggleIcon(function () {
                    _this.element.$menu.slideUp(150);
                    // _this.addOverlay();
                  });
                }
              });

              _this.element.$menu.on('click', function (e) {
                e.stopPropagation();
              });

              if (docClickHandlerAdded === false) {
                docClickHandlerAdded = true;
              }
            };

            this.element.$menuToggle.on('click', function (e) {

              e.preventDefault();
              e.stopPropagation();

              _this.toggleIcon(function () {
                if (_this.element.$menu.is(':visible')) {
                  _this.element.$menuReservation.show();
                } else {
                  _this.element.$menuReservation.hide();
                }

                _this.element.$menu.slideToggle(150);
                // _this.addOverlay();
              });

              if (docClickHandlerAdded === false) {
                addDocumentClick();
              }
            });
          },
          toggleIcon: function toggleIcon(callback) {
            var $navToggle = $('.nav-toggle');
            $navToggle.toggleClass('active');
            callback();
          },
          addOverlay: function addOverlay() {
            if ($('.menu-overlay').length) {
              $('.menu-overlay').remove();
            } else {
              this.element.$menu.after($(this.element.overlayDiv));
            }
          }
        };

        navigation.init();
      })();

      // Sticky global navigation
      (function () {

        var stickyNav = {

          element: {
            $nav: $('.nav-pc'),
            stickyNav: 'sticky-nav',
            $logo: $('.header-logo a')
          },

          config: {
            stickyEnabled: false,
            stickyCreated: false,
            stickyHeight: 80,
            stickyHeightNarrow: 91
          },

          init: function init() {
            this.scroll();
          },
          scroll: function scroll() {
            var _this = this;
            var scrollPos = void 0;
            var stickyPos = void 0;

            $(window).scroll(function () {
              scrollPos = $(window).scrollTop();
              stickyPos = _this.element.$nav.offset().top + _this.element.$nav.outerHeight();

              if (scrollPos > stickyPos && _this.config.stickyEnabled !== true) {
                if (_this.config.stickyCreated !== true) {

                  _this.createStickyNav();
                  _this.slideDown();

                  _this.config.stickyCreated = true;
                } else {
                  _this.slideDown();
                }

                _this.config.stickyEnabled = true;
              }

              if (scrollPos < stickyPos && _this.config.stickyEnabled !== false) {
                _this.slideUp();

                _this.config.stickyEnabled = false;
              }
            });
          },
          createStickyNav: function createStickyNav() {
            this.element.$nav.clone().addClass(this.element.stickyNav).prependTo('body');

            // ToDo: Refactor/Clean
            $('.js_reservation a').on('click', function (e) {
              e.preventDefault();

              var target = $(this).attr('href').replace('#', '');
              var offset = $('#' + target).offset().top - 40;

              $('html,body').animate({ scrollTop: offset }, 700);

              return 0;
            });
          },
          slideDown: function slideDown() {
            $('.' + this.element.stickyNav).animate({
              top: '0'
            }, 500);
          },
          slideUp: function slideUp() {
            if ($(window).width() > 950) {
              $('.' + this.element.stickyNav).animate({
                top: '-' + this.config.stickyHeight
              }, 500);
            } else {
              $('.' + this.element.stickyNav).animate({
                top: '-' + this.config.stickyHeightNarrow
              }, 500);
            }
          },
          fadeInLogo: function fadeInLogo() {
            setTimeout(function () {
              $('.nav__logo img').css({ visibility: 'visible' }).animate({ opacity: 1 }, 1800);
            }, 400);
          },
          hideLogo: function hideLogo() {
            $('.nav__logo img').css({ visibility: 'hidden', opacity: 0 });
          }
        };

        // Only enable the sticky dropdown navigation on the top page
        if ($('.page-top').length) {
          stickyNav.init();
        }
      })();

      // ScrollMagic
      (function () {

        var scrollMagic = {

          element: {
            img1: '.js_animate1',
            img2: '.js_animate2',
            img3: '.js_animate3',
            img4: '.js_animate4',
            trigger1: '.js_trigger1'
          },

          config: {
            animationTime: 3
          },

          init: function init() {
            this.createScenes();
          },
          createScenes: function createScenes(sceneNum) {
            var _this = this;

            // Bezier curves defined
            var curves = {
              img1: {
                curviness: 1.25,
                // autoRotate: true,
                values: [{ x: 0, y: 0 }, { x: -225, y: -40 }, { x: -450, y: 60 }]
              },
              img2: {
                curviness: 1.25,
                // autoRotate: true,
                values: [{ x: 0, y: 0 }, { x: 175, y: -10 }, { x: 350, y: 25 }]
              },
              img3: {
                curviness: 2,
                // autoRotate: true,
                values: [{ x: 0, y: 0 }, { x: 250, y: -10 }, { x: 400, y: 30 }]
              },
              img4: {
                curviness: 1,
                // autoRotate: true,
                values: [{ x: 0, y: 0 }, { x: -225, y: 0 }, { x: -400, y: 40 }]
              }
            };

            // Scene1 (mame)
            var controller1 = new ScrollMagic.Controller();
            var scene1 = new ScrollMagic.Scene({
              triggerElement: _this.element.trigger1,
              triggerHook: 'onEnter',
              offset: 100,
              reverse: false
            }).setTween(_this.element.img1, 4, { css: { bezier: curves.img1 }, ease: Power1.easeOut }) // trigger a TweenMax.to tween
            // .addIndicators({name: '1 (duration: 0)'}) // add indicators (requires plugin)
            .addTo(controller1);

            // Scene2 (sake)
            var controller2 = new ScrollMagic.Controller();
            var scene2 = new ScrollMagic.Scene({
              triggerElement: _this.element.img2,
              triggerHook: 'onCenter',
              offset: -200,
              reverse: false
            }).setTween(_this.element.img2, 4, { css: { bezier: curves.img2 }, ease: Power1.easeOut }) // trigger a TweenMax.to tween
            // .addIndicators({name: '1 (duration: 0)'}) // add indicators (requires plugin)
            .addTo(controller2);

            // Scene3 (fish)
            var controller3 = new ScrollMagic.Controller();
            var scene3 = new ScrollMagic.Scene({
              triggerElement: _this.element.img3,
              triggerHook: 'onEnter',
              reverse: false
            }).setTween(_this.element.img3, 3, { css: { bezier: curves.img3 }, ease: Power1.easeOut }) // trigger a TweenMax.to tween
            // .addIndicators({name: '1 (duration: 0)'}) // add indicators (requires plugin)
            .addTo(controller3);

            // Scene4 (bottle)
            var controller4 = new ScrollMagic.Controller();
            var scene4 = new ScrollMagic.Scene({
              triggerElement: _this.element.img4,
              triggerHook: 'onCenter',
              reverse: false
            }).setTween(_this.element.img4, 3.5, { css: { bezier: curves.img4 }, ease: Power1.easeOut }) // trigger a TweenMax.to tween
            // .addIndicators({name: '1 (duration: 0)'}) // add indicators (requires plugin)
            .addTo(controller4);
          }
        };

        // Only run scrollmagic on the top page
        if ($('.page-top').length && $(window).width() > 767) {
          scrollMagic.init();
        }
      })();

      // Slider
      (function () {

        var slider = {

          el: {
            $slider: $('.js_slider')
          },

          init: function init() {
            if (this.el.$slider.length) {
              this.runSlider();
              this.resizeHandler();
            }
          },
          runSlider: function runSlider() {
            var _this = this;

            _this.el.$slider.slippry({
              pager: false,
              controls: false,
              autoHover: false,
              loop: true,
              pause: 6000,
              speed: 1500,
              captions: false,
              onSliderLoad: function onSliderLoad() {
                _this.verticallyCenterSlides();
              }
            });
          },
          resizeHandler: function resizeHandler() {
            var _this = this;
            var resizeTimer = void 0;

            $(window).on('resize', function () {
              clearTimeout(resizeTimer);

              resizeTimer = setTimeout(function () {
                _this.verticallyCenterSlides();
              }, 250);
            });
          },
          verticallyCenterSlides: function verticallyCenterSlides() {
            var $slides = this.el.$slider.find('.sy-slide');

            var sliderHeight = this.el.$slider.height();
            var slideHeight = $slides.height();

            var offset = (slideHeight - sliderHeight) / 2;

            if (offset > 0) {
              $slides.css('top', '-' + offset + 'px');
            } else {
              $slides.css('top', '0');
            }
          }
        };
        slider.init();
      })();

      // Scroll to reservation
      (function () {
        $('.js_reservation a, .js_reservation-sp a').on('click', function (e) {
          e.preventDefault();

          var target = $(this).attr('href').replace('#', '');
          var offset = $('#' + target).offset().top - 40;

          $('html,body').animate({ scrollTop: offset }, 700);

          return 0;
        });
      })();

      // Delay loading of images
      (function () {
        [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
          img.setAttribute('src', img.getAttribute('data-src'));
          img.onload = function () {
            img.removeAttribute('data-src');
          };
        });
      })();

      // Tabbed page
      (function () {

        var tabbedPage = {

          el: {
            $nav: $('.js_nav-food'),
            $tab: $('.page-tab')
          },

          conf: {
            specialAnchors: ['dinner-course', 'dinner-alacarte', 'dinner-celebrate']
          },

          init: function init() {
            this.clickHandler();
            this.openTabFromURL();
          },
          clickHandler: function clickHandler() {
            var _this = this;
            this.el.$nav.on('click', 'a', function (e) {
              e.preventDefault();

              var targetID = $(this).attr('href');
              _this.toggleTab(targetID);
              _this.toggleLinkImg($(this).find('img'));

              return 0;
            });
          },
          toggleTab: function toggleTab(id) {
            var $tab = $(id);

            this.el.$tab.removeClass('active');
            $tab.toggleClass('active');
          },
          openTabFromURL: function openTabFromURL() {
            var _this = this;

            var anchor = this.getURLAnchor();
            var tabAnchors = this.getTabAnchors();

            if ($.inArray(anchor, tabAnchors) >= 0) {

              if (anchor.indexOf('-') === -1) {
                _this.toggleTab('#' + anchor);
                _this.toggleLinkImg($('a[href="' + '#' + anchor + '"] img'));
              } else {
                var tab = anchor.substring(0, anchor.indexOf('-'));
                var innerAnchor = anchor.substring(anchor.indexOf('-') + 1, anchor.lastIndexOf(anchor.slice(-1)) + 1);

                _this.toggleTab('#' + tab);
                _this.toggleLinkImg($('a[href="' + '#' + tab + '"] img'));

                setTimeout(function () {
                  _this.slideDownToAnchor(innerAnchor);
                }, 500);
              }
            }
          },
          slideDownToAnchor: function slideDownToAnchor(target) {
            var navHeight = 0;
            if ($(window).width() > 767) {
              navHeight = $('.nav-pc').height() * 1.4;
            } else {
              navHeight = $('.nav-sp').height() * 1.2;
            }
            var offset = $('#' + target).offset().top - navHeight;
            $('html,body').animate({ scrollTop: offset }, 1000);
          },
          getURLAnchor: function getURLAnchor() {
            var anchor = window.location.hash.substr(1);
            return anchor;
          },
          getTabAnchors: function getTabAnchors() {
            var anchors = [];
            this.el.$nav.find('a').each(function () {
              var anchor = $(this).attr('href').replace('#', '');
              anchors.push(anchor);
            });

            return anchors.concat(this.conf.specialAnchors);
          },
          toggleLinkImg: function toggleLinkImg($img) {
            this.el.$nav.find('img').attr('src', function (index, attr) {
              return attr.replace('--active.png', '.png');
            });
            $img.attr('src', function (index, attr) {
              return attr.replace('.png', '--active.png');
            });
          }
        };

        if ($('.page-tab').length) {
          tabbedPage.init();
        }
      })();

      // Sticky go to top link
      (function () {

        var stickyLink = {

          el: {
            $link: $('.js_to-top')
          },

          init: function init() {
            this.sticky();
            this.clickhandler();
          },
          sticky: function sticky() {
            var offset = $(window).height() - (58 + 14);
            this.el.$link.stick_in_parent({
              offset_top: offset
            });
          },
          clickhandler: function clickhandler() {
            this.el.$link.find('a').on('click', function (e) {
              e.preventDefault();
              $('html, body').animate({ scrollTop: 0 });
              return 0;
            });
          }
        };

        stickyLink.init();
      })();
    });
  });
})(window.jQuery, window, document);